import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Author } from "rily"
import { MediumTitle } from "../../components/typography/title"
import { Text, CaptionText } from "../../components/typography/paragraph"
import TwoPaneWrapper from "../../components/twoPaneWrapper"
import { generateCookTimeString } from "../../utils/recipe"
import { slug } from "../../utils/slug"
import { graphql } from "gatsby"
import SEO from "../../components/seo"

const AuthorContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const AuthorInfo = styled.div`
  padding: 0 20px;
  margin-top: 100px;
  margin-bottom: 20px;
  position: sticky;
  top: 20px;
  text-align: center;

  span {
    color: ${({ theme }) => theme.colors.gray};
  }

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  @media (min-width: 768px) {
    padding: 0 20px 0 55px;
    margin-top: 155px;
    text-align: left;

    img {
      width: calc(16.666vw - 35px);
      height: calc(16.666vw - 35px);
      min-width: 175px;
      max-width: 250px;
      max-height: 250px;
      min-height: 175px;
    }
  }
`
const Bio = styled(Text)`
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: initial;
  }
`
const AuthorRecipeList = styled.div`
  background: ${({ theme }) => theme.colors.floralWhite};
  padding: 20px;
  a {
    all: unset;
  }

  @media (min-width: 768px) {
    padding: 55px 55px 20px 20px;
  }
`
const RecipeCard = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding-right: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  display: flex;
  cursor: pointer;
`
const FeaturedImage = styled.img`
  object-fit: cover;
  width: calc(33.333vw - 50px);
  min-width: 150px;
  max-width: 250px;
  height: 140px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  @media (min-width: 768px) {
    width: calc(16.666666vw - 50px);
    min-width: 175px;
    max-width: 250px;
    height: 155px;
  }
`
const CardCopy = styled.div`
  margin-left: 10px;
  padding: 15px 0;
  display: grid;

  @media (min-width: 768px) {
    margin-left: 20px;
    padding: 20px 0;
  }
`
const SubText = styled(CaptionText)`
  color: ${({ theme }) => theme.colors.gray};
  span {
    color: ${({ theme }) => theme.colors.black};
  }
  a:visited {
    color: ${({ theme }) => theme.colors.gray};
  }
  a {
    all: unset;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`
const Time = styled(SubText)`
  margin-right: 10px;
  @media (min-width: 768px) {
    margin-right: 20px;
  }
`
const Flex = styled.div`
  display: flex;
  align-items: flex-end;
`

const AuthorInfoSection = ({ author }: { author: Author }) => {
  return (
    <AuthorInfo>
      <MediumTitle>
        {author.name}
        <br />
        <span>{author.role}</span>
      </MediumTitle>
      <img src={author.image}></img>
      <Bio>
        {author.bio}
        <br />
        <br />
      </Bio>

      {author.instagramUrl && (
        <SubText>
          <a href={author.instagramUrl} target="_blank">
            Instagram
          </a>
        </SubText>
      )}
    </AuthorInfo>
  )
}

const AuthorPage = ({ data }) => {
  const author = data.allAuthor.authors[0].node

  let featuredRecipes = author.recipes.map((item, index) => {
    let imageSource = item.images[0]
    let imagePath = imageSource.replace(
      "https://res.cloudinary.com/heat-supply/image/upload/",
      "https://res.cloudinary.com/heat-supply/image/upload/w_300,q_auto,dpr_auto/"
    ) //replaces cloudinary params with smaller thumbnails
    return (
      <Link to={`/recipes/${slug(item.title)}`} key={index}>
        <RecipeCard>
          <FeaturedImage src={imagePath} />
          <CardCopy>
            <MediumTitle>{item.title}</MediumTitle>
            <Flex>
              <Time>
                Time <span>{generateCookTimeString(+item.cookTime)}</span>
              </Time>
            </Flex>
          </CardCopy>
        </RecipeCard>
      </Link>
    )
  })

  return (
    <>
      <SEO
        title={`Recipes By ${author.name}`}
        pagePath={slug(author.name)}
        image={author.image}
      />
      <AuthorContainer>
        <TwoPaneWrapper
          left={<AuthorInfoSection author={author} />}
          right={
            <AuthorRecipeList>
              <SubText>Recipes</SubText>
              {featuredRecipes}
            </AuthorRecipeList>
          }
        />
      </AuthorContainer>
    </>
  )
}

export const query = graphql`
  query AuthorPageQuery($id: String) {
    allAuthor(filter: { id: { eq: $id } }) {
      authors: edges {
        node {
          instagram_url
          id
          bio
          image
          name
          recipes: recipe_data {
            cookTime: cook_time
            id
            images
            title
          }
          role
        }
      }
    }
  }
`

export default AuthorPage
